import SwitchComponent from "../form/Switch";
import { edit } from "../../slices/settingsSlice";
import Header from "../navigation/Header";
import "./Settings.css"
import TextArea from "../form/TextArea";
import TubeSubmit from "../form/TubeSubmit";
import { useState } from "react";
import { useSelector } from "react-redux";
import { appUrl, post } from "../../utils/fetch";
import ButtonSwitch from "../form/ButtonSwitch";

export default function Settings(props){
    const [ isLodingSubmit, setIsLoadingSubmit ] = useState(false);
    const settings = useSelector((state) => state.settings);

    const submit = () => {
        post(`${appUrl}/api/settings`, settings, () => setIsLoadingSubmit(false), () => setIsLoadingSubmit(true));
    }

    return (
        <div className="settings">
            <Header title={"Settings"} />
            <div className="settings-form">
                <SwitchComponent
                    label={"Adult warning"}
                    stateName={"settings"}
                    valueName={"adultWarning"}
                    dispatchFunc={edit}
                />
                <SwitchComponent
                    label={"Cookies notification"}
                    stateName={"settings"}
                    valueName={"cookiesNotification"}
                    dispatchFunc={edit}
                />
                <TextArea
                    label={"Cookies notification text"}
                    stateName={"settings"}
                    valueName={"cookiesNotificationContent"}
                    dispatchFunc={edit}
                />
                <SwitchComponent
                    label={"SuperAdmin access"}
                    stateName={"settings"}
                    valueName={"allowSuperAdmin"}
                    dispatchFunc={edit}
                />
                <ButtonSwitch
                    label={"Units"}
                    stateName={"settings"}
                    valueName={"units"}
                    dispatchFunc={edit}
                    data={[
                        { id: "metric", label: "Metric" },
                        { id: "imperial", label: "Imperial" }
                    ]}
                />
                <TubeSubmit
                    isLoading={isLodingSubmit}
                    onClick={submit}
                    label={"Save"}
                />
            </div>
        </div>
    );
}