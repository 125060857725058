import './App.css';
import { Outlet } from "react-router-dom";
import Navigation from './components/navigation/Navigation';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import TubeButton from './components/form/TubeButton';
import { appUrl, get, post } from './utils/fetch';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { edit } from './slices/settingsSlice';
import { edit as videosFilterEdit } from './slices/videosFilterSlice';
import { edit as advertisementsFilterEdit } from './slices/advertisementsFilterSlice';
import { edit as albumsFilterEdit } from './slices/albumsFilterSlice';
import { edit as channelsFilterEdit } from './slices/channelsFilterSlice';
import { edit as contentTagsFilterEdit } from './slices/contentTagsFilterSlice';
import { edit as historyFilterEdit } from './slices/historyFilterSlice';
import { edit as modelsFilterEdit } from './slices/modelsFilterSlice';
import { edit as pagesFilterEdit } from './slices/pagesFilterSlice';
import { edit as usersFilterEdit } from './slices/usersFilterSlice';
import { edit as usersGroupsFilterEdit } from './slices/usersGroupsFilterSlice';

function App() {
    const dispatch = useDispatch();

    const [ pass, setPass ] = useState("");
    const [ passErr, setPassErr ] = useState(false);
    const [ isLoggedIn, setIsLoggedIn ] = useState(Cookies.get('isLoggedIn'));

    const login = () => {
        post(`${appUrl}/api/login`, {
            password: pass
        }, (result) => {
            if(result.data === true){
                Cookies.set('isLoggedIn', 'true');
                setIsLoggedIn('true');
            }
            else {
                setPassErr(true);
            }
        })
    }

    useEffect(() => {
        if(isLoggedIn === "true"){
            get(
                `${appUrl}/api/settings`,
                (data) => {
                    dispatch(edit({...data.data}));
                    if(data.data && data.data.videosFilter){
                        let videosFilter = JSON.parse(data.data.videosFilter);
                        dispatch(videosFilterEdit({...videosFilter}));
                    }
                    if(data.data && data.data.advertisementsFilter){
                        let advertisementsFilter = JSON.parse(data.data.advertisementsFilter);
                        dispatch(advertisementsFilterEdit({...advertisementsFilter}));
                    }
                    if(data.data && data.data.albumsFilter){
                        let albumsFilter = JSON.parse(data.data.albumsFilter);
                        dispatch(albumsFilterEdit({...albumsFilter}));
                    }
                    if(data.data && data.data.channelsFilter){
                        let channelsFilter = JSON.parse(data.data.channelsFilter);
                        dispatch(channelsFilterEdit({...channelsFilter}));
                    }
                    if(data.data && data.data.contentTagsFilter){
                        let contentTagsFilter = JSON.parse(data.data.contentTagsFilter);
                        dispatch(contentTagsFilterEdit({...contentTagsFilter}));
                    }
                    if(data.data && data.data.historyFilter){
                        let historyFilter = JSON.parse(data.data.historyFilter);
                        dispatch(historyFilterEdit({...historyFilter}));
                    }
                    if(data.data && data.data.modelsFilter){
                        let modelsFilter = JSON.parse(data.data.modelsFilter);
                        dispatch(modelsFilterEdit({...modelsFilter}));
                    }
                    if(data.data && data.data.pagesFilter){
                        let pagesFilter = JSON.parse(data.data.pagesFilter);
                        dispatch(pagesFilterEdit({...pagesFilter}));
                    }
                    if(data.data && data.data.usersGroupsFilter){
                        let usersGroupsFilter = JSON.parse(data.data.usersGroupsFilter);
                        dispatch(usersGroupsFilterEdit({...usersGroupsFilter}));
                    }
                    if(data.data && data.data.usersFilter){
                        let usersFilter = JSON.parse(data.data.usersFilter);
                        dispatch(usersFilterEdit({...usersFilter}));
                    }
                }
            )
        }
    }, [isLoggedIn]);

    return (
        <>
        {isLoggedIn !== "true" ? (
            <div className='login-window'>
                <TextField 
                    id={"password"}
                    label={"Password"}
                    variant="outlined"
                    type={"password"}
                    className="text-input"
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                />
                <TubeButton
                    title="Log in"
                    onClick={() => login()}
                />
                {passErr ? (
                    <div className='incorrect-password'>
                        Password is incorrect!
                    </div>
                ) : null}
            </div>
        ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en"}>
                <div 
                    className="App"
                    style={{
                        display: "inline-flex",
                        width: "100%",
                        fontFamily: "arial !important"
                    }}
                >
                    <Navigation />  
                    <div className="content" style={{
                        padding: "20px 30px",
                        width: "100%",
                        marginLeft: 232,
                        position: "relative",
                        paddingTop: 90
                    }}>
                        <Outlet />
                    </div>
                </div>
            </LocalizationProvider>
        )}
        </>
    );
}

export default App;
